const english = {
  translations: {
    "AboutUs": "About Us",
    "Date": "Date",
    "FooterCopyright": "COPYRIGHT © 2010 [company_name]. ALL RIGHTS RESERVED.",
    "Home": "Home",
    //end error message
    "Results": 'Results',
    'DrawNo': 'Draw No.',
    "FooterContent": "Disclaimer Singapore77 Lottery is a 100% Free 4D ( Lottery ) Results Site, Government Licensed Lottery Are Legal in Malaysia & Singapore, Singapore77 Lottery Do Not Encourage Illegal Betting And Do Not Control Any Of Results Posted In Here. Other Companies Name, Logo And Services Mentioned Here May Be Trademarks Or Trade Names Of Their Respective Owners",
    "AboutUsContent": `Disclaimer Singapore77 Lottery is a 100% free 4D (lottery) results site, Government licensed lottery are legal in Malaysia & Singapore, Singapore77 Lottery do not encourage illegal betting and do not control any of results posted in here. Other companies name, logo and services mentioned here may be trademarks or trade names of their respective owners. 

If you have any questions on this website, please email us. Singapore77 Lottery takes all reasonable steps to ensure that the information is accurate, but does not provide a statement or guarantee (express or implied) regarding the accuracy or completeness of the content of this website. You are responsible for making any decisions based on the information on your website. (Including but not limited to any special, direct, consequential or consequential loss or damage, any loss or any form of damage), or any loss or damage to Singapore77 Lottery, its directors, employees or agents related to this website or Its content. If the information on this website and the rules and procedures of the Singapore77 Lottery game (amended from time to time), any rules and conflicting procedures will prevail.

All pages and their content on this website, including design, text and graphics, have copies.`,
    "CompanyName": "SINGAPOREPOOLS77"
  }
};

export default english;
