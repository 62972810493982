import { makeStyles } from "@material-ui/core/styles";
import { color, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  winners: {
    position: 'relative',
    display: 'flex',
    // maxWidth: '700px',
    // width: '90%',
    // margin: 'auto',
    // border: `1px solid ${color.primary}`,
    borderRadius: '8px',
    background: 'linear-gradient(to right, #000000,  #ffffff )',
    backgroundColor: color.tableBackground,
    boxShadow: '0px 0px 15px 15px rgba(41,91,140,0.5)',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      // border: `0 solid ${color.primary}`,
      background: 'none',
      borderBottom :`1px solid #393558`,
      maxWidth: '1560px',
      width: '100%',
      boxShadow: '0px 0px 0px 0px rgba(41,91,140,0.5)',
    },
  },

  winners__table__wrapper: {
    borderRadius: '8px',
    margin: '1px',
    padding: '1px',
    backgroundColor: color.tableBackground,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      fontSize: font.nomalSize__mobile,
    },
  },

  winners__table: {
    display: 'inline-block',
    // width: '28%',
  },

  ball__left: {
    display: 'flex',
    transform: 'translate(-100%,-100%)',
    position: 'absolute',
    height: '80%',
    width: 'auto',
    left: '0',
    top: '30%',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  ball__right: {
    transform: 'translate(100%,-100%)',
    display: 'flex',
    position: 'absolute',
    height: '40%',
    width: 'auto',
    right: '-1%',
    top: '20%',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },

  winner1stCol: {
    paddingLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '6.25vw',
    },
  },
}));
