import React from "react";
import { useTranslation } from 'react-i18next';
import Image from 'react-image-webp';
import lableTitle from 'assets/img/home/label_title.png';
import lableTitleWepb from 'assets/imgWebp/label_title.webp';
import { useStyles } from './styles';

const AboutUs = () => {
  const classes = useStyles()
  const {t} = useTranslation();
  
  return (
    <div className={classes.container_page} style={{animation: 'fadeIn ease-in .1s'}}>
      <div className={classes.about_title}>
          <span>
          <Image alt='label title' src={lableTitle} webp={lableTitleWepb} className={classes.lable_image} />
          {t('AboutUs')}</span>
      </div>
      <div className={classes.content}>
        <span>
          <div className={classes.content_center}>
            <pre>
              {t('AboutUsContent')}
            </pre>
          </div>
        </span>
      </div>
      <br />
    </div>
  )
};

export default AboutUs
