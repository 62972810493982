import { createContext } from 'react';
import { get, map } from 'lodash';
import Api from 'services/Api';
import {stringToArray} from './../utils'

const mapDataHomeResult = function(data) {
    const periodList = get(data, 'periodList', []);
	const prizeListData = get(data, 'result.prizeList', [])
	const singlePrizeListData = get(data, 'result.singlePrizeList', [])
	
    const singlePrizeList = map(singlePrizeListData,(item, index)=> {
      return {
        ...item,
        id: index,
        numbers: stringToArray(item.result)
      }
	})
	
	const prizeList = map(prizeListData, (item, index)=> {
		return {
			...item,
			id: index,
			resuls: item.result.map((ressultString)=>stringToArray(ressultString))
		  }
	})

	return {
		periodList,
		singlePrizeList,
		prizeList
	}
}
export const AuthContext = createContext({
  dataList: {},
  resultHomeData: {
	periodList: [], 
	singlePrizeList: [], 
	prizeList: []
  },
  mapDataHomePage: mapDataHomeResult,
  requestAuth: Api.requestAuthData,
  requestPeriodDateDropList: Api.requestPeriodDateList
});
