
import HTTPService from './HTTPServices';
const ApiDomain = process.env.REACT_APP_API_DOMAIN;

const Api = {};
Api.GetPoolList = function (func) {
    return HTTPService.sendPostReqeust(ApiDomain + '/api/v1/top-winners')
}

Api.GetPoolList = function () {
    return HTTPService.sendPostReqeust(ApiDomain + '/api/v1/top-winners')
}

Api.requestAuthData = function () {
    return HTTPService.sendPostReqeust(ApiDomain + '/result')
};

Api.requestPeriodDateList = function() {
    return HTTPService.sendPostReqeust(ApiDomain + '/period_list')
}

Api.requestPoolByPeriod = function(period) {
    return HTTPService.sendPostReqeust(ApiDomain + '/result_by_period', undefined, {period})
}

Api.requestPageResult = function () {
    return HTTPService.sendPostReqeust(ApiDomain + '/result_paging', undefined, {page:0, page_size: 18})
}

Api.requestMeta = function (endpoint = '') {
    return HTTPService.sendPostReqeust(ApiDomain + '/metadata', undefined, {endpoint})
}

export default Api;