import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Hidden } from '@material-ui/core';
import ChangeLanguage from './../../components/ChangeLanguage';

import { useStyles } from "./styles"

const Menu = () => {
  const classes = useStyles();
  const {t} = useTranslation();

  return <div className={classes.menu__list}>
      <Hidden smDown>
          <div className={classes.menu__item}>
            <ChangeLanguage/>
          </div>
      </Hidden>
      <div className={classes.menu__routers}>
        <div className={classes.menu__item}>
          <NavLink exact activeClassName={classes.menu__item_selected} to={'/'}>{t('Home')}
            <div className='after'/>
          </NavLink>
        </div>

        <div className={classes.menu__item}>
          <NavLink exact activeClassName={classes.menu__item_selected} to={'/results'}>{t('Results')}
            <div className='after'/>
          </NavLink>
        </div>

        <div className={classes.menu__item}>
          <NavLink exact activeClassName={classes.menu__item_selected} to={'/about-us'}>{t('AboutUs')}
            <div className='after'/>
          </NavLink>
        </div>
      </div>
    </div>
};

export default Menu
