import { makeStyles } from "@material-ui/core/styles";
import {layout } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  containner: {
    position: 'relative',
    width: '100%',
    minHeight: layout.headerHeighBg,
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      minHeight: layout.headerHeighBg__mobile,
      '& ball__left': {
        display: 'flex'
      }
    },
  },

  img_logo: {
    position: 'absolute',
    left: '50%',
    bottom: '0%',
    width: '50%',
    transform: 'translateX(-50%)',
  },

  ball__left: {
    display: 'none',
    position: 'absolute',
    height: '60%',
    width: 'auto',
    left: '2%',
    bottom: '7%',
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    },
  },
  ball__right: {
    display: 'none',
    position: 'absolute',
    height: '20%',
    width: 'auto',
    right: '2%',
    bottom: '7%',
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    },
  },
}));
