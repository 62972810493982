import React, {useRef, useState} from 'react';
import Image from 'react-image-webp';
import Flicking from '@egjs/react-flicking';
import {
	SlideButton,
} from './components';

import imgButtonPrev from 'assets/img/button/button_prev.png';
import imgButtonPrevWepb from 'assets/imgWebp/button_prev.webp';
import imgButtonNext from 'assets/img/button/button_next.png';
import imgButtonNextWepb from 'assets/imgWebp/button_next.webp';
import { useStyles } from './styles';

const SwipableView = props => {
	const [enableButtonLeft, setEnableButtonLeft] = useState('none');
	const [enableButtonRight, setEnableButtonRight] = useState('inline-block');
	const numItems = React.Children.count(props.children);

	const classes = useStyles();
	const flicking = useRef(null);
	const handleClickNext = ()=> {
		if (flicking) {
			flicking.current.next();
		}
	}

	const handleClickPrev = ()=> {
		if (flicking) {
			flicking.current.prev();
		}
	}

	const onChange = (e)=> {
		if(flicking) {
			let buttonLeft = 'inline-block'
			let buttonRight = 'inline-block'

			if (e.index === (numItems - 1)) {
				buttonRight = 'none'
			}

			if(e.index === 0) {
				buttonLeft = 'none'
			}

			setEnableButtonLeft(buttonLeft)
			setEnableButtonRight(buttonRight)
		}
	}
	

	return (
		<>
		<Flicking ref={flicking} onChange={onChange} zIndex={0}>
			{props.children}
		</Flicking>
		<SlideButton className={classes.buttonLeft} onClick={handleClickPrev} hide={enableButtonLeft}>
			<Image className={classes.buttonImage} alt='button prev' src={imgButtonPrev} webp={imgButtonPrevWepb} />
		</SlideButton>
		<SlideButton className={classes.buttonRight} onClick={handleClickNext} hide={enableButtonRight}>
			<Image alt='button next' className={classes.buttonImage} src={imgButtonNext} webp={imgButtonNextWepb} />
		</SlideButton>
		</>
	);
};



export default SwipableView;
