import React from 'react';
import { map } from 'lodash';

import { useStyles } from './styles';

const RTable = ({data = {}, style, level, hBorderTopLeftRadius, hBorderTopRightRadius , onlyOne}) => {
  const classes = useStyles();

  const renderHeader = () => {
    const classNameHeader = `table__header_${level}`
    const { header } = data;
    const hStyle = {};  
    if (hBorderTopLeftRadius) {
      hStyle.borderTopLeftRadius = '1vh'
    }

    if(hBorderTopRightRadius) {
      hStyle.borderTopRightRadius = '1vh'
    }

    return <div className={classes[classNameHeader]} style={hStyle} >
      {
        map(header, (head, i) =>
          <div className={classes.row_header_table} key={i}>{head}</div>
        )
      }
    </div>
  };

  const renderBody = () => {
    const { body } = data;

    return map(body, (row, i) => {
      let classNameRow = classes.body_bg_1
          if (i%2 === 0) {
            classNameRow = classes.body_bg_2
          }
    return <div key={i} className={classNameRow}>
      {
        map(row, (data, j) => {
          return <div className={classes.row_table} key={j}>{data}</div>}
        )
      }
    </div>})
  };
  
  let className = 'table__body';
  if (level === 1) {
    className = 'table__body_1'
  }

  let headerClass = classes.table__header
  if (onlyOne) {
    headerClass = classes.table__header_one_element
  }

  return <div className={classes.table} style={style}>
    <div className={headerClass}>
      {renderHeader()}
    </div>
    <div className={classes[className]}>
      {renderBody()}
    </div>
  </div>
};

export default RTable;
