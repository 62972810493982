import React from "react";
import { useTranslation } from "react-i18next";

import { StyledContainer } from "config/styles";
import { useStyles } from "./styles";

const Footer = () => {
	const { t } = useTranslation()
	const classes = useStyles();

	const companyName = t('CompanyName');
	let footerContent = t('FooterCopyright');
	footerContent = footerContent.replace('[company_name]',companyName)

	return <div className={classes.footer}>
		<StyledContainer>
			<div className={classes.footerClass}>
				<div className={classes.footerLeft}>
					<div className={classes.footerTextLeft}>
						{t('FooterContent')}
					</div>
				</div>
				<div className={classes.line} />
				<div className={classes.footerRight}>
					{footerContent}
				</div>
			</div>
		</StyledContainer>
	</div>
};

export default Footer
