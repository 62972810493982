import React, { useContext } from 'react';
import { AuthContext } from 'context/AuthContext'
import TableWinners from './TableWinners';
import TableTopThreeView from './TableTopThree';

import { useStyles } from './styles';

const Home = () => {
	const classes = useStyles()
	const { singlePrizeList, prizeList } = useContext(AuthContext).resultHomeData;
	
	return (
		<div className={classes.container_page} style={{ animation: 'fadeIn ease-in .3s' }}>
			<TableTopThreeView data={singlePrizeList} />
			{prizeList.map((item, index) => {
				return <TableWinners key={index} index={index} data={item} />
			})}
			<br />
		</div>
	)
};

export default Home
