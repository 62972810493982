import { makeStyles } from "@material-ui/core/styles";

import { color, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  menu__list: {
    display: 'flex',
    padding: '20px 0 10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      backgroundColor: '#29273f',
      padding: '0px',
    }
  },
  menu__routers: {
    display: 'flex',
    padding: '20px 0 10px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0 10px',
    }
  },
  menu__item: {
    marginRight: '45px',
    color: color.black,
    [theme.breakpoints.down('sm')]: {
      color: 'white',
      marginRight: '20px',
      marginLeft: '20px',
    }
  },

  menu__item_selected: {
    display: 'inline-block',
    color: color.black,
    fontFamily: font.boldFamily,
    '& .after': {
      display: 'none',
      content: ' ',
      border: `1px solid ${color.primary}`,
      borderRadius: '3px',
      backgroundColor: color.primary,
      width: '23px',
      height: '3px',
      marginTop: '2px',
    },
    [theme.breakpoints.down('sm')]: {
      color: '#20b3fd',
      '& .after': {
        display: 'flex',
        border: `1px solid #20b3fd`,
        backgroundColor: '#20b3fd',
      }
    }
  },
}));
