import { makeStyles } from "@material-ui/core/styles";

import { color } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  container_page: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: color.mainBackground
    }
  },
}));
