import React from 'react';
import Image from 'react-image-webp';
import TableNumber from 'components/Numbers';
import { useStyles } from './styles';

import BallLeft from 'assets/img/home/ball_left.png';
import BallLeftWepb from 'assets/imgWebp/ball_left.webp';
import BallRight from 'assets/img/home/ball_right.png';
import BallRightWepb from 'assets/imgWebp/ball_right.webp';

const TableTopThree = ({data}) => {
  const classes = useStyles()
  const renderTableDesktop = () => {
    return (
      <div className={classes.winners__table__wrapper}>
        <Image alt='ball top three' src={BallLeft} webp={BallLeftWepb} className={classes.ball__left} />
        <Image alt='ball top three' src={BallRight} webp={BallRightWepb} className={classes.ball__right} />
        {
          data.map((item, index)=> {
            return <div key={index} className={classes.winners__table}>
                <TableNumber data={item} />
            </div>
          })
        }
      </div>
    )
  };
  
  return <div className={classes.winners}>
    {renderTableDesktop()}
  </div>
};

export default TableTopThree;
