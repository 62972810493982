import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  buttonRight: {
    right: '-35px',
    [theme.breakpoints.down('sm')]: {
      right: '-20px',
    }
  },

  buttonLeft: {
    left: '-35px',
    [theme.breakpoints.down('sm')]: {
      left: '-20px',
    }
  },

  buttonImage: {
    minWidth: '5px',
    maxWidth: '34px',
    width: '25px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '18px',
    }
  },
}));
