import { makeStyles } from "@material-ui/core/styles";

import { color, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  winners: {
    margin: 'auto',
    marginTop: '5px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '1.5vw',
      width: '90%',
    },
  },


  winners__table__wrapper: props => ( {
  display: 'flex',
    borderRadius: '8px',
    boxShadow: 'inset 0px 0px 15px 10px rgba(0,0,0,0.15)',
    width: '100%',
    background: props.index === 0 ? 'linear-gradient(to right, #000000,  #ffffff )': 'linear-gradient(to right, #2b57d2,  #c64db4  )',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
      boxShadow: 'inset 0px 0px 0px 0px rgba(0,0,0,0.15)',
    },
  }),

  winners__table: {
    borderRadius: '8px',
    backgroundColor: color.tableBackground,
    display: 'inline-block',
    width: '100%',
    margin: '1px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  winner_title: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    '& span': {
      margin: '0px',
      height: '35px',
      fontSize: font.largeSize,
      fontFamily: font.boldFamily,
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        fontSize: font.largeSize__mobile,
      }
    }
  },

  image_title: {
    height: '12px',
    width: 'auto',
    margin:'2px 2px 0px 2px',
    [theme.breakpoints.down('sm')]: {
      margin:'2px 2px 0px 2px',
    }
  },
}));
