import { makeStyles } from "@material-ui/core/styles";

import { color, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    borderSpacing: '0px',
    borderCollapse: 'collapse',
    borderRadius: '8px',
    overflow: 'hidden',
    '& th, td': {
      border: '1px solid black',
    },
    '& table': {
      borderCollapse: 'collapse',
      // borderRadius: '1em',
      overflow: 'hidden'
    },
    fontSize: font.largeSize,
    [theme.breakpoints.down('sm')]: {
      fontSize: font.largeSize__mobile,
    },
  },

  table__header: {
    '& tr': {
      height: '60px',
      backgroundColor: color.tableHeader,
    },
    '& th': {
      textAlign: 'left',
    },

    [theme.breakpoints.down('sm')]: {
      '& tr': {
        height: '9.375vw',
      },
    },
  },

  table__body: {
    '& tr': {
      height: '40px',
      backgroundColor: '#11101d'
    },

    [theme.breakpoints.down('sm')]: {
      '& tr': {
        height: '6.25vw',
      },
    },
  },
  table_container_numers: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    flexDirection: 'colum',
    marginTop:'5px',
    marginBottom:'5px',
  },
  table_number: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    padding: '5px',
    minWidth: '20px',
    minHeigh: '20px',
    maxWidth: '40px',
    maxHeigh: '40px',
    borderRadius: '50%',
    marginLeft: '5px',
    marginRight: '5px',
    width: '6vw',
    heigh: '6vw',
    backgroundColor: '#0c0c16',
    [theme.breakpoints.down('sm')]: {
      minWidth: '15px',
      minHeigh: '15px',
    },
  }
}));
