import React, { useState, useEffect, useContext } from "react";
import { get, map } from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import Image from 'react-image-webp';
import RTable from './RTable';
import Api from 'services/Api';
import { AuthContext } from 'context/AuthContext'
import lableTitle from 'assets/img/home/label_title.png';
import lableTitleWepb from 'assets/imgWebp/label_title.webp';
import SwipeableView from './../../components/SwipableView'
import { withWindowSizeListener } from 'react-window-size-listener';


import { useStyles } from './styles';

const Results = ({ windowSize }) => {
	const { periodList } = useContext(AuthContext).resultHomeData;
	const [open, setOpen] = useState(false);
	const [itemSelect, setItemSelect] = useState('');
	const [dataRender, setDataRender] = useState({
		numberPage: 0,
		pages: [],
		arrayPeriod: [],
		numberItemRender: -1
	});

	const classes = useStyles();
	const { t } = useTranslation();

	const periodSearchToString = (item) => {
		return `${item.dayOfWeek}, ${item.date}`
	}

	const handleRequestData = (periodDay, first) => {
		setItemSelect(periodDay)
		Api.requestPoolByPeriod(periodDay).then((res) => {
			const isSuccess = get(res, 'data.success', false);
			if (!isSuccess) {
				// can not get data
				//render maintainer page
			} else {
				const data = get(res, 'data.data', {});
				handleUpdatePages(data, first, true)
			}
		});
	}

	const mapDataToTable = (data, hasOne) => {
		const prizeListData = get(data, 'prizeList', [])
		const singlePrizeListData = get(data, 'singlePrizeList', [])

		const dateString = periodSearchToString(data)
		const periodString = `${t('DrawNo')} ${data.period}`
		const header = [dateString, periodString]
		const body = map(singlePrizeListData, (item, index) => {
			return [item.name, item.result]
		})
		const singlePrizes =  [{
			header,
			body
		}]

		const prizeList = map(prizeListData, (item, index) => {
			const header = item.result.length > 1 ? [item.name, ''] : [item.name]
			const body = [];
			let count = 0
			let resulItem = []
			item.result.forEach(resul => {
				resulItem.push(resul)
				count++
				if (count === 2) {
					body.push(resulItem)

					resulItem = []
					count = 0
				}
			});

			if (resulItem.length > 0) {
				body.push(resulItem)
			}

			return {
				header,
				body
			}
		})

		return {
			hasOne,
			singlePrizes,
			secondPrizes: prizeList.slice(0, 1),
			thirdPrizes: prizeList.slice(1, prizeList.length),
		}
	}

	const handleOpenDropdown = () => {
		setOpen(true)
	}

	const handleCloseDropdown = () => {
		setOpen(false)
	}

	const handleChangeDropdown = (event) => {
		const periodSelect = event.target.value;
		if (periodSelect !== itemSelect) {
			handleRequestData(periodSelect);
		}
	};

	useEffect(() => {

		Api.requestPageResult().then((res) => {
			const isSuccess = get(res, 'data.success', false);
			if (!isSuccess) {
				// can not get data
				//render maintainer page
			} else {
				const data = get(res, 'data.data', {});
				handleUpdatePages(data, true)
				console.log('request data', data);
			}
		});
		
		const searchPeriod = periodList[0]
		if (searchPeriod) {
			setItemSelect(searchPeriod.period)
		// 	handleRequestData(searchPeriod.period, true)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [periodList])

	useEffect(() => {
		console.log('handle map data', dataRender);
		
		const { data , first } = dataRender
		if (data && data.length > 0) {
			handleUpdatePages(data, first);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowSize.windowWidth])

	const handleUpdatePages = (arrayPeriodData, first, change) => {
		const hasOne = arrayPeriodData.length <= 1
		const arrayPeriod = map(arrayPeriodData, (data) => mapDataToTable(data, hasOne))

		let numberPeriodOnlyPage = 3
		if (windowSize.windowWidth < 960) {
			numberPeriodOnlyPage = 1
		} else if (windowSize.windowWidth < 1200) {
			numberPeriodOnlyPage = 2
		}

		if (dataRender.numberItemRender === numberPeriodOnlyPage) {
			if (!change)
				return
		}
		
		const pages = []
		const numberPeriods = arrayPeriod.length
		let numberPage = 0
		let countPage = 0;
		let pageItem = []
		for (let index = 0 ; index < numberPeriods; index++ ) {
			pageItem.push(arrayPeriod[index])
			++countPage
			if (countPage === numberPeriodOnlyPage) {
				pages.push(pageItem)
				numberPage++
				countPage = 0
				pageItem = []
			}
		}

		if (pageItem.length > 0 ) {
			pages.push(pageItem)
			numberPage++
		}

		const pagesData = {
			numberItemRender: numberPeriodOnlyPage,
			first,
			data: arrayPeriodData,
			numberPeriods,
			arrayPeriod,
			numberPage,
			pages
		}
		
		setDataRender(pagesData);
	}

	const handleRederDesktop = (listRender, level, hasOne) => {
		if (!listRender) return null
		let style = null;
		if (hasOne) {
			style = {
				width: '100%',
			}
		}
		return listRender.map((item, index) => {
			return <RTable style={style} key={index} data={item} level={level} />
		})
	}

	const handleRederMultDesktop = (listRender, level, hasOne) => {
		if (!listRender) return null
		let table1 = null
		let table2 = null
		
		listRender.forEach((item, index) => {
			const header0 = [item.header[0]]
			const header1 = [item.header[1]]
			const body0 = []
			const body1 = []
			item.body.forEach(element => {
				body0.push([element[0]])
				body1.push([element[1]])
			});

			table1 = {
				header: header0,
				body: body0
			}
			table2 = {
				header: header1,
				body: body1
			}
		})

		const style1 = {
			width: '50%',
			marginRight: '2px',
		}

		const style2 = {
			width: '50%',
			marginLeft: '2px',
		}

		return  <div className={classes.result_container_content}> 
				<RTable style={style1}  data={table1} level={level} onlyOne /> 
				<RTable style={style2}  data={table2} level={level} onlyOne /> 
			</div>
	}

	const renderContentWeb = () => {
		const {arrayPeriod, numberPage, pages} = dataRender
		if (numberPage <= 1) {
			return <div className={classes.result_container_content}>
					{
						map(arrayPeriod, (period, index)=> {
							const {hasOne ,singlePrizes, secondPrizes, thirdPrizes} = period
							return <div key={index} className={classes.result_content}>
								{
									handleRederMultDesktop(singlePrizes, 1, hasOne)
								}
								{
									handleRederDesktop(secondPrizes, 2, hasOne)
								}

								{
									handleRederDesktop(thirdPrizes, 3, hasOne)
								}
							</div>
						})
					}
				</div>
		} else {
			return <SwipeableView title='swip period' className={classes.slideContainer} >
					{
						map(pages, (page, index) => {
							const hasOne = page.length <=1 
							return <div key={index} className={classes.result_container_content}>
								{
									map(page, (period, periodInbox) => {
										const {singlePrizes, secondPrizes, thirdPrizes} = period
										return <div key={periodInbox} className={classes.result_content}>
											{
												handleRederDesktop(singlePrizes, 1,hasOne)
											}
											{
												handleRederDesktop(secondPrizes, 2, hasOne)
											}

											{
												handleRederDesktop(thirdPrizes, 3, hasOne)
											}
										</div>
									})
								}
							</div>
						})
					}
				</SwipeableView>
		}
	}

	return (
		<div className={classes.container_page} style={{ animation: 'fadeIn ease-in .1s' }}>
			<div className={classes.container}>
				<div className={classes.result_container_header}>
					<div className={classes.result_title}>
						<span>
						<Image alt='label title' src={lableTitle} webp={lableTitleWepb} className={classes.lable_image} />
						{t('Results')}</span>
					</div>
					<FormControl className={classes.formControl} hiddenLabel>
						<Select
							labelId="demo-controlled-open-select-label"
							id="demo-controlled-open-select"
							open={open}
							onClose={handleCloseDropdown}
							onOpen={handleOpenDropdown}
							value={itemSelect}
							onChange={handleChangeDropdown}
						>
							{
								map(periodList, (item, index) => <MenuItem key={index} value={item.period}>{periodSearchToString(item)}</MenuItem>)
							}
						</Select>
					</FormControl>
				</div>
				{renderContentWeb()}
			</div>
		</div>
	)
};

export default withWindowSizeListener(Results)
