import React from 'react';
import Image from 'react-image-webp';
import Logo from 'assets/img/home/logo.png';
import LogoWepb from 'assets/imgWebp/logo.webp';
import { useStyles } from './styles'

import BallLeft from 'assets/img/home/ball_left.png';
import BallLeftWepb from 'assets/imgWebp/ball_left.webp';
import BallRight from 'assets/img/home/ball_right.png';
import BallRightWepb from 'assets/imgWebp/ball_right.webp';


const LogoCompany = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.containner}>
        <Image alt='logo' src={Logo} webp={LogoWepb} className={classes.img_logo} />
        <Image alt='ball top three' src={BallLeft} webp={BallLeftWepb} className={classes.ball__left} />
        <Image alt='ball top three' src={BallRight} webp={BallRightWepb} className={classes.ball__right} />
      </div>
    </>
  )
};

export default LogoCompany
