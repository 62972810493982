import { makeStyles } from "@material-ui/core/styles";
import { font } from "../../../config/styles";
export const useStyles = makeStyles((theme) => ({
  table: {
    display: 'flex',
    // flex: '1',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    marginBottom:'2vh',
    // tableLayout: 'fixed',
    // borderCollapse: 'collapse',
    // borderTopLeftRadius: '6px',
   '& tr, td': {
      borderSpacing: '0px',
      cellPadding: '0px',  
      padding: '0',
      margin: '0'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3vw',
    }
  },

  table__header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    width: '100%',
    '& div': {
      height: '60px',
      width: '100%',
    },
    '& th': {
      textAlign: 'center',
    },
    '& th:nth-child(1)': {
      borderTopLeftRadius: '6px',
    },
    '& th:nth-child(2)': {
      borderTopRightRadius: '6px',
    },

    [theme.breakpoints.down('sm')]: {
      '& tr': {
        height: '9.375vw',
      },
    },
  },

  table__header_one_element: {
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    borderRadius: '10px',
    '& div': {
      height: '60px',
      width: '100%',
    },
    '& th': {
      textAlign: 'center',
    },
    '& th:nth-child(1)': {
      
    },

    [theme.breakpoints.down('sm')]: {
      '& tr': {
        height: '9.375vw',
      },
    },
  },

  table__header_1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    background: 'linear-gradient(to bottom, #fecb59 20%, #ed8733 100%)',
    backgroundClip: 'border-box',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    '& div': {
      textAlign: 'center',
      height: '40px',
      paddingLeft: '10px' 
    },
  },

  table__header_2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    background: 'linear-gradient(to bottom, #1586c2 20%, #044d8c 100%)',
    '& div': {
      justifyContent: 'flex-start',
      height: '40px',
      textAlign: 'left',
      paddingLeft: '10px' 
    }
  },

  table__header_3: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    background: 'linear-gradient(to bottom, #7c7f7d 20%, #555754 100%)',
    '& div': {
      justifyContent: 'flex-start',
      height: '40px',
      textAlign: 'left',
      paddingLeft: '10px' 
    }
  },

  row_header_table: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    fontFamily: font.boldFamily
  },

  table__body: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    '& div': {
      height: '40px',
      textAlign: 'center'
    }, 
    [theme.breakpoints.down('sm')]: {
      '& div': {
        height: '6.25vw',
      },
    }
  },

  table__body_1: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: '#1a7de3',
    '& div': {
      height: '40px',
      textAlign: 'center'
    },

    '& :nth-child(1)': {
      '& div': {
        color: '#ffc400'  
      }
    },
    '& :nth-child(2)': {
      '& div': {
        color: '#30c013'  
      }
    },

    '& :nth-child(3)': {
      '& div': {
        color: '#1a7de3' 
      }
    },
    
    [theme.breakpoints.down('sm')]: {
      '& tr': {
        height: '6.25vw',
      },
    }
  },

  row_table: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  body_bg_1: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#282540'
  },

  body_bg_2: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-round',
    backgroundColor: '#211f35'
  }
}));
