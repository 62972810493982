import React from 'react';
import Image from 'react-image-webp';
import Table from 'components/Table';
import { useStyles } from './styles';

import lableTitle from 'assets/img/home/label_title.png';
import lableTitleWepb from 'assets/imgWebp/label_title.webp';

const TableWinners = ({ data, index }) => {
  const props = {index}
  const classes = useStyles(props);
  
  const mapDataToTable = (data = [], header = []) => {
    if (data.length > 0) {
      const body = [];
      for (let i = 0; i < 10 ; i+=2) {
        const b = data.slice(i,i + 2)
        if (b.length > 0) {
          body.push(b)
        } else {
          break
        }
      }
      return  {
        header,
        body
      };
    }

    return  {
      header,
      body: data
    };
  };

  const renderTableDesktop = () => {
    const items = data.resuls;
    const table1 = mapDataToTable(items)
    return (
      <div className={classes.winners__table__wrapper}>
        <div className={classes.winners__table}>
          <Table data={table1} />
        </div>
      </div>
    )
  };

  const renderTitle = () => {
    return (
      <div className={classes.winner_title}>
          <span>
          <Image alt='label title' src={lableTitle} webp={lableTitleWepb} className={classes.image_title} />
          {data.name}
          </span>
      </div>
    )
  }
  
  return <div className={classes.winners}>
    {renderTitle()}
    {renderTableDesktop()}
  </div>
};

export default TableWinners;
