import styled from "styled-components";

export const NEXT = "NEXT";
export const PREV = "PREV";

export const CarouselContainer = styled.div`
  display: flex;
  transition: ${props => (props.sliding ? "none" : "transform 1s ease")};
  transform: ${props => {
		if (!props.sliding) return "translateX(calc(-100%))";
		if (props.dir === PREV) return "translateX(calc(2 * (-100%)))";
		return "translateX(0%)";
	}};
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const CarouselSlot = styled.div`
  flex: 1 0 100%;
  flex-basis: 100%;
  order: ${props => props.order};
`;

export const SlideButton = styled.button`
	display: ${props => props.hide};
	position: absolute;
	bottom: 45%;
    background-color: transparent;
    border: 0px solid white;
    text-decoration: none;
    cursor: pointer;
  	left: ${props => props.left };
	right: ${props => props.right};
	&:active {

	}
	&:focus {
		outline: 0;
	}
`;