import { makeStyles } from "@material-ui/core/styles";
import { color, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  numbers: {
    display: 'flex',
    padding: '5px',
    // width: '100%',
    borderCollapse: 'collapse',
    justifyContent:'center',
    alignItems:'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
    },
  },

  number_title: {
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'flex-start',
    width:'100%',
  },

  header_title: {
    '& span': {
      fontFamily: font.boldFamily,
      fontSize: font.largeSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: font.largeSize__mobile
      }
    },
  },

  number__body: {
    display: 'flex',
    width: '100%',
    borderCollapse: 'collapse',
    justifyContent:'space-around',
    alignItems:'center',
    flexDirection: 'row',
  },

  number_content: {
    background: 'linear-gradient(to bottom, #4f9ac0,#2f4170)',
    borderRadius: '6px',
    color: '#122dff',
    display: 'inline-block',
    fontSize: '45px',
    padding: '1px',
    textDecoration: 'none',
    margin: '5px',
    '& span': {
      fontSize: '45px',
        background: color.dialogBackground,
        display: 'block',
        padding: '0.15em 0.2em',
        borderRadius: '6px',
        [theme.breakpoints.down('sm')]: {
          borderRadius: '2px',
          fontSize: font.bigSize__mobile,
        },
    },
    [theme.breakpoints.down('sm')]: {
      margin: '3px',
      borderRadius: '3px',
    },
  }
}));
